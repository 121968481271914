import Title from "../Title/Index";

function Nav() {
  return (
    <>
      <nav>
        <Title title="Busca tu perfil de github :D !!!" />
      </nav>
    </>
  );
}
export default Nav;
